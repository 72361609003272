<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2>
          Список процессов автоматического резервирования
          <i v-if="isLoadingActualList || isLoadingArchiveList" class="el-icon-loading" style="margin-left: 10px;" />
        </h2>
        <el-tabs>
          <el-tab-pane label="Актуальное">
            <ReserveTransfersTable :handle-is-loading="handleIsLoadingActualList" />
          </el-tab-pane>
          <el-tab-pane label="Архив">
            <ReserveTransfersTable is-archive :handle-is-loading="handleIsLoadingArchiveList" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </main>
    <el-footer class="page__active_bar" />
  </el-container>
</template>

<script>
import ReserveTransfersTable from "./ReserveTransfersTable.vue";

export default {
  name: "ReserveTransfers",
  components: { ReserveTransfersTable },
  data() {
    return {
      isLoadingActualList: true,
      isLoadingArchiveList: true,
    };
  },
  methods: {
    handleIsLoadingActualList(isLoading) {
      this.isLoadingActualList = isLoading;
    },
    handleIsLoadingArchiveList(isLoading) {
      this.isLoadingArchiveList = isLoading;
    },
  },
};
</script>

<style>
</style>
