<template>
  <div>
    <el-table
      :data="items"
      :span-method="getSpanMethod"
      :row-class-name="getRowClasses"
      :row-key="row_key"
      :expand-row-keys="expandRowKeys"
      @expand-change="handleExpandChange"
    >
      <el-table-column type="expand">
        <template slot-scope="scope">
          <massive-reserve-transfer-list
            v-if="scope.row.type === 'massive'"
            :data="scope.row.groups"
            :go-to-lk-operator="goToLKOperator"
            :is-return-button-loading="isReturnButtonLoading"
            :handle-return-cameras-button="handleReturnCamerasButton"
          />
          <massive-reserve-from-one-server-list
            v-else
            :data="scope.row.groups[0].transfers"
            :go-to-lk-operator="goToLKOperator"
            :is-return-button-loading="isReturnButtonLoading"
            :handle-return-cameras-button="handleReturnCamerasButton"
          />
        </template>
      </el-table-column>
      <el-table-column label="Сервер" prop="server_name_from">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.type === 'single'"
            content="Перейти в ЛК Оператора"
            :open-delay="1000"
          >
            <el-link type="primary" @click="goToLKOperator(scope.row.groups[0].transfers[0].server_from, token)" class="link">
              {{ scope.row.groups[0].transfers[0].server_from.name }}
            </el-link>
          </el-tooltip>
          <template v-else>
            <b>МАССОВОЕ РЕЗЕРВИРОВАНИЕ</b>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Резервный сервер" prop="server_name_to">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.type === 'single'"
            content="Перейти в ЛК Оператора"
            :open-delay="1000"
          >
            <el-link type="primary" @click="goToLKOperator(scope.row.groups[0].transfers[0].server_to, token)" class="link">
              {{ scope.row.groups[0].transfers[0].server_to.name }}
            </el-link>
          </el-tooltip>
          <template v-else>
            {{ scope.row.groups[0].transfers[0].server_to.name }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Статус" prop="status_description">
        <template slot-scope="scope">
          <template v-if="scope.row.type === 'massive'">
            {{ scope.row.status_description || 'Нет данных' }}
          </template>
          <template v-else>
            <router-link
              v-if="scope.row.groups[0].transfers[0].is_report_accessible === 1"
              class="link"
              :to="{ name: 'reserve-transfers-report:report', params: { id: scope.row.groups[0].transfers[0].id_reserve_transfer } }"
            >
              {{ scope.row.groups[0].transfers[0].status_description }}
            </router-link>
            <template v-else>
              {{ scope.row.groups[0].transfers[0].status_description }}
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Дата/время" prop="start_date">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'single'">{{ scope.row.groups[0].transfers[0].start_dt }}</span>
          <template v-else>
            <span>{{ scope.row.start_date }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.type === 'single' && scope.row.status !== 11"
            class="return_button"
            size="small"
            type="primary"
            :loading="isReturnButtonLoading"
            @click="() => handleReturnCamerasButton(scope.row.groups[0].transfers[0])"
          >
            Вернуть камеры на свой сервер
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="list__pagination"
      layout="prev, pager, next"
      :page-size="count"
      :total="total"
      :current-page="page"
      @current-change="handleChangePage"
    />
  </div>
</template>

<script>
import Vue from "vue";
import MassiveReserveTransferList from "./MassiveReserveTransferList.vue";
import MassiveReserveFromOneServerList from "./MassiveReserveFromOneServerList.vue";
import formatAppUrl from "@/core/helpers/formatAppUrl";
import { VK_OPERATOR_TOKEN_COOKIE_KEY } from "@/core/helpers/const";
import { mapActions, mapState } from "vuex";

export default {
  name: "ReserveTransfersTable",
  components: { MassiveReserveFromOneServerList, MassiveReserveTransferList },
  props: {
    isArchive: {
      type: Boolean,
      default: false,
    },
    handleIsLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      isReturnButtonLoading: false,
      expandRowKeys: [],
      items: [],
      total: 0,
      count: 50,
      page: 1,
    };
  },
  computed: {
    ...mapState("reserveTransfers", {
      itemsArchive: (state) => state.archiveReserveTransfers.data,
      totalArchive: (state) => state.archiveReserveTransfers.total,
      countArchive: (state) => state.archiveReserveTransfers.count,
      itemsActual: (state) => state.actualReserveTransfers.data,
      totalActual: (state) => state.actualReserveTransfers.total,
      countActual: (state) => state.actualReserveTransfers.count,
    }),
    ...mapState("auth", ["token"]),
  },
  watch: {
    loading() {
      this.handleIsLoading(this.loading);
    },
    itemsArchive() {
      if (this.isArchive) {
        this.items = this.itemsArchive;
      }
    },
    totalArchive() {
      if (this.isArchive) {
        this.total = this.totalArchive;
      }
    },
    countArchive() {
      if (this.isArchive) {
        this.count = this.countArchive;
      }
    },
    itemsActual() {
      if (!this.isArchive) {
        this.items = this.itemsActual;
      }
    },
    totalActual() {
      if (!this.isArchive) {
        this.total = this.totalActual;
      }
    },
    countActual() {
      if (!this.isArchive) {
        this.count = this.countActual;
      }
    },
  },
  created() {
    if (this.isArchive) {
      this.items = this.itemsArchive;
      this.total = this.totalArchive;
      this.count = this.countArchive;
    } else {
      this.items = this.itemsActual;
      this.total = this.totalActual;
      this.count = this.countActual;
    }

    this.loading = true;

    this.getList({
      page: this.page,
      count: this.count,
      return_status: this.isArchive ? 1 : 0,
    }).then(() => (this.loading = false));
    this.intervalId = setInterval(() => {
      this.loading = true;
      this.getList({
        page: this.page,
        count: this.count,
        return_status: this.isArchive ? 1 : 0,
      }).then(() => (this.loading = false));
    }, 8000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions("reserveTransfers", {
      getList: "GET_LIST",
      returnCameras: "RETURN_CAMERAS",
    }),
    goToLKOperator(item, token) {
      const url = `${formatAppUrl(process.env.VUE_APP_LK_OPERATOR_DOMAIN || item.operator_domain)}auth?operator=${item.id_operator}&redirectPath=servers/edit/${item.id}`;
      Vue.$cookies.set(VK_OPERATOR_TOKEN_COOKIE_KEY, token);
      window.open(url, "_blank");
    },
    handleReturnCamerasButton(reserveTransfer) {
      this.$confirm(
        `Вернуть камеры с сервера ${reserveTransfer.server_to.name}
          на сервер ${reserveTransfer.server_from.name}`,
        "Возврат камер",
        {
          confirmButtonText: "Вернуть",
        }
      ).then(() => {
        this.isReturnButtonLoading = true;

        this.returnCameras(reserveTransfer.id_reserve_transfer)
          .then(() => {
            this.$message({
              message: "Камеры возвращены",
              type: "success",
            });
            this.getList({
              page: this.page,
              count: this.count,
              return_status: this.isArchive ? 1 : 0,
            });
          })
          .catch((e) => {
            if (e !== "cancel") {
              this.$message({
                type: "error",
                message: e?.response?.data.error || "Неизвестная ошибка",
              });
            }
          })
          .finally(() => { this.isReturnButtonLoading = false });
      });
    },
    getSpanMethod({ row, columnIndex }) {
      if (row.type === "massive") {
        if (columnIndex === 1) {
          return [1, 2];
        } else if (columnIndex === 2) {
          return [0, 0];
        }
      }
    },
    getRowClasses({ row }) {
      return row.type === "single" && row.groups[0].transfers.length === 1 ? "no-expand" : null;
    },
    row_key(row) {
      return row.id_report;
    },
    handleExpandChange(row) {
      const id = row.id_report;
      const indexExpandedRow = this.expandRowKeys.findIndex((idExpanded) => id === idExpanded);
      if (indexExpandedRow !== -1) {
        this.expandRowKeys.splice(indexExpandedRow, 1);
      } else {
        this.expandRowKeys.push(id);
      }
    },
    handleChangePage(page) {
      this.expandRowKeys = [];
      this.page = page;

      this.loading = true;
      this.getList({
        page,
        count: this.count,
        return_status: this.isArchive ? 1 : 0,
      }).then(() => (this.loading = false));
    },
  },
};
</script>

<style>
.list,
.list__pagination {
  margin: 20px 0;
}
.list__pagination {
  text-align: center;
}
.no-expand .el-icon {
  display: none;
}
.no-expand .el-table__expand-icon {
  pointer-events: none;
}
.return_button {
  text-wrap: wrap;
  width: 144px;
}
</style>
